.copy-panel {
	color: $color-three;

	&--light {
		color: $color-one;

		.copy-panel__copy::before {
			background-color: $color-one;
		}
	}

	&__container {}

	&__heading {
		max-width: 41rem;
		margin-bottom: 2.3rem;
	}

	&__copy {
		padding-left: 33.33%;

		> * {
			@include font-preset--10;
		}

		a {
			color: $color-eleven;
			text-decoration: none;
		}

		em {
			color: $color-eleven;
			font-style: normal;
		}

		&::before {
			content: "";
			display: block;
			margin-bottom: 2.8rem;
			width: 9.3rem;
			height: 1px;
			background-color: $color-three;
		}
	}

	@media (max-width: 670px) {
		&__copy {
			padding-left: 0;
		}
	}
}
