$img-grid-gutter: 1.8rem;

.img-grid {
	color: $color-one;
	background-color: $color-three;

	&--light {
		color: $color-three;
		background-color: $color-one;
	}

	&__container {
		padding: 2.1rem;
	}

	&__heading {
		margin-bottom: 2.7rem;
	}

	&__content {
		display: flex;
		flex-wrap: wrap;
		// justify-content: center;
		margin-right: - $img-grid-gutter;
	}

	&__item {
		width: calc(20% - #{$img-grid-gutter});
		padding-bottom: calc(14% - #{$img-grid-gutter});
		margin: 0 $img-grid-gutter $img-grid-gutter 0;
		background-size: contain;
		background-position: 50%;
		background-repeat: no-repeat;
		transition: opacity 0.4s;

		@media (min-width: 700px) {
			background-size: 7rem;
		}

		@media (min-width: 1000px) {
			background-size: 10rem;
		}


	}

	a.img-grid__item:hover {
		opacity: 0.5;
	}

	@media (max-width: 680px) {
		&__item {
			width: calc(25% - #{$img-grid-gutter});
			padding-bottom: calc(15% - #{$img-grid-gutter});
		}
	}

	@media (max-width: 550px) {
		&__item {
			width: calc(50% - #{$img-grid-gutter});
			padding-bottom: calc(23% - #{$img-grid-gutter});
		}
	}
}
