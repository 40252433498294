$testimonials-b-1: 850px;

.testimonials {
	color: $color-one;
	background-color: $color-three;

	&__container {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	&__heading {
		width: 100%;

		@media (min-width: $testimonials-b-1) {
			width: calc(33.33% - 5rem);
		}
	}

	&__slides {
		width: 100%;
		margin-top: 2rem;

		@media (min-width: $testimonials-b-1) {
			width: 66.66%;
			margin-top: 0;
		}
	}

	&__slide {
		margin-right: 1rem;
	}

	&__logo {
		max-width: 150px;
		margin-bottom: 1.75rem;
	}
	
	&__quote {
		font-size: 1.87rem;
		margin-bottom: 2.1rem;

		@media (max-width: 1300px) {
			font-size: 1.3rem;
		}
	}

	.slick-arrow {
		display: none !important;
	}

	.slick-dots {
		position: relative;
		text-align: left;
		// bottom: 0.5rem;


		li {
			width: 14px;
			height: 14px;
			border-radius: 100%;
			background-color: $color-one;
			margin: 0 1.25rem 0 0;

			&.slick-active {
				background-color: $color-eleven;
			}

			button {
				display: none;
			}
		}
	}
}