$cards-gutter: 1.8rem;

.cards {
	margin-top: -4.3rem;
	color: $color-one;
	background-color: $color-three;

	&__container {
		padding-top: 0;
	}

	&__content {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin-right: - $cards-gutter;
	}

	&__heading {
		width: calc(100% - #{$cards-gutter});
		margin: 2.2rem 0;
	}

	&__item {
		background-color: $color-twelve;
		color: $color-one;
		width: calc(33.33% - #{$cards-gutter});
		margin: 0 $cards-gutter $cards-gutter 0;
		padding: 0.68rem 1.25rem 1rem;

		h3 {
			margin-bottom: 0.5rem;
		}

		p {
			margin-bottom: 1.1rem;
		}
	}

	&__contact {
		display: flex;
		align-items: center;

		li {
			max-width: 24px;
			margin: 0 20px 0 0;

			&:last-of-type {
				margin: 0;
			}
		}
	}

	&__bio-toggle {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		padding: 0.25rem;
		max-width: 1.5rem;
		cursor: pointer;
		transition: ease 0.4s;
	}

	&__bio-content {
		padding: 0 2rem 1.5rem 0;

		p {
			margin-bottom: 0;
		}
	}

	@media (max-width: 800px) {
		&__item {
			width: calc(50% - #{$cards-gutter});
		}
	}

	@media (max-width: 530px) {
		&__item {
			width: calc(100% - #{$cards-gutter});
		}
	}
}
