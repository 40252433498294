.info-block {
	background-color: $color-seven;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 0% 50%;

	&--parallax {
		min-height: 400px;
		background: transparent;
	}

	&__container {
		display: flex;
		max-width: $container-three;
		margin: 0 auto;
		z-index: 1;
	}

	&__item {
		flex: 1;
		padding-bottom: 12rem;
		color: $color-one;
		margin-top: -3rem;

		&--dark {
			color: $color-three;

			.info-block__copy::before {
				background-color: $color-three;
			}
		}
	}

	&__heading,
	&__copy {
		max-width: 650px;
	}

	&__heading {
		margin-bottom: 1.5rem;
	}

	&__copy {
		&::before {
			content: "";
			display: block;
			margin-bottom: 1rem;
			width: 3.5rem;
			height: 1px;
			background-color: $color-one;
		}
	}

	@media (max-width: 680px) {
		&__item {
			margin-top: -2rem;
		}
	}

	@media (max-width: 580px) {
		&__container {
			flex-wrap: wrap;
		}

		&__item {
			flex: 100%;

			&:first-of-type:not(:last-of-type) {
				padding-bottom: 0;
				margin-bottom: -5rem;
			}
		}
	}
}
