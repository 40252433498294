/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
	position: relative;
}

html {
	color: $color-three;
	padding: 0;
	scroll-behavior: smooth;
	font-size: $font-size-one;
	font-family: $font-one;
	line-height: $line-height;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: 'tnum';
	font-variant-numeric: tabular-nums;
	transition: ease 0.4s;

	@media (max-width: 1100px) {
		// font-size: $font-size-two; off for accurate spacing
	}
}

ul,
ol {
	padding: 0;
	list-style: none;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
	font: inherit;
	color: inherit;
	margin: 0;
}

/* Set core body defaults */
body {
	min-height: 100vh;
	text-rendering: optimizeSpeed;
	overflow-x: hidden;
}

img,
svg,
video,
hr {
	width: 100%;
	display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font: inherit;
}

/* Table Stuff */
table,
th,
td {
	border: 1px solid currentColor;
	text-align: left;
}

td,
th {
	padding: 1rem;
	vertical-align: top;
}

table {
	border-collapse: collapse;
	margin: 2rem 0;
}

th {
	font-weight: 800;
}

@media (prefers-reduced-motion: reduce) {
	* {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

.skip-main {
	left: -999px;
	position: absolute;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
	z-index: -999;

	&:focus,
	&:active {
		position: static;
		left: 0;
		right: 0;
		top: 0;
		width: 100%;
		height: auto;
		overflow: auto;
		background: #fff;
		padding: 5px;
		text-align: center;
		z-index: 999;
	}
}
