$font-break-1: 1300px;
$font-break-2: 850px;

@mixin font-preset--1 {
	font-size: 4.3rem;
	margin: 0 0 3.1rem;
	font-weight: 800;

	@media (max-width: $font-break-1) {
		font-size: 3rem;
	}

	@media (max-width: $font-break-2) {
		font-size: 2.5rem;
	}
}

@mixin font-preset--2 {
	font-size: 3.1rem;
	margin: 0 0 2.8rem;
	font-weight: 800;

	@media (max-width: $font-break-1) {
		font-size: 2.6rem;
	}

	@media (max-width: $font-break-2) {
		font-size: 2.1rem;
	}
}

@mixin font-preset--3 {
	font-size: 2.8rem;
	margin: 0 0 2rem;
	font-weight: 800;

	@media (max-width: $font-break-1) {
		font-size: 2.3rem;
	}

	@media (max-width: $font-break-2) {
		font-size: 1.6rem;
	}
}

@mixin font-preset--4 {
	font-size: 2.3rem;
	margin: 0 0 2.5rem;
	font-weight: 800;

	@media (max-width: $font-break-1) {
		font-size: 1.8rem;
	}

	@media (max-width: $font-break-2) {
		font-size: 1.3rem;
	}
}

@mixin font-preset--5 {
	font-size: 1.62rem;
	margin: 0 0 1.8rem;
	font-weight: 800;

	@media (max-width: $font-break-1) {
		font-size: 1.12rem;
	}
}

@mixin font-preset--6 {
	font-size: 16px;
	margin: 0 0 1.25rem;
	font-weight: 800;
}

@mixin font-preset--7 {
	font-size: 16px;
	margin: 0 0 16px;
}

@mixin font-preset--8 {
	font-size: 1.25rem;
	margin: 0 0 1.62rem;
}

@mixin font-preset--9 {
	font-size: 1.62rem;
	margin: 0 0 1.62rem;

	@media (max-width: $font-break-1) {
		font-size: 1.12rem;
	}
}

@mixin font-preset--10 {
	font-size: 1.87rem;
	margin: 0 0 1.62rem;

	@media (max-width: $font-break-1) {
		font-size: 1.3rem;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
li,
p {
	@include font-preset--7;

	margin: 0 0 2.6ex;
	line-height: 1.3;
}

.font-preset--1,
h1 {
	@include font-preset--1;
}

.font-preset--2,
h2 {
	@include font-preset--2;
}

.font-preset--3,
h3 {
	@include font-preset--3;
}

.font-preset--4,
h4 {
	@include font-preset--4;
}

.font-preset--5,
h5 {
	@include font-preset--5;
}

.font-preset--6,
h6 {
	@include font-preset--6;
}

.font-preset--7 {
	@include font-preset--7;
}

.font-preset--8 {
	@include font-preset--8;
}

.font-preset--9 {
	@include font-preset--9;
}

.font-preset--10 {
	@include font-preset--10;
}
