// SPACING
$space-one: 2.1rem;
// $space-two: 2.5rem;
// $space-three: 5.6rem;

// Superwide global breakpoint for layout tweaks
$superWide: 2300px;

// CONTAINER WIDTHS
// $container-one: 105rem; // container
$container-two: 75rem; // container--medium
$container-three: 89.5rem; // container--large
$container-four: 54.4rem; // container--narrow

// FONTS
$font-size-one: 16px;
$font-size-two: 14px;
$line-height: 1.4;
$font-one: 'TeXGyreAdventor', sans-serif;

// COLOURS
$color-one: #fff; // WHITE
$color-two: #000; // BLACK
$color-three: #232323; // MAIN FONT COLOUR
$color-four: #33464d; // DARK GREEN
$color-five: #133145; // DARK BLUE
$color-six: #ebece4; // PALE YELLOW
$color-seven: #697e91; // PALE BLUE
$color-eight: #001e1f; // DARK GREENYBLUE
$color-ten: #cfcfcf; // MID GREY
$color-eleven: #656565; // MID GREY TWO
$color-eleven: #8bbdb6; // HIGHLIGHT
$color-twelve: #393939; // TEAM CARD

// TRANSITIONS
$transition-one: ease 0.4s;
$transition-two: ease 0.8s;
$transition-three: ease 1s;
