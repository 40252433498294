.pagination {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	&__button {
		text-decoration: none;
		color: inherit;

		&--prev {
			margin-right: 0.5rem;
		}

		&--next {
			margin-left: 0.5rem;
		}
	}

	&__list {
		li {
			margin: 0 0.5rem;
		}

		a {
			color: inherit;
		}
	}

	&__current {}
}
