.features {
	background-color: $color-five;
	color: $color-one;

	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: baseline;
	}

	&__heading {
		width: calc(33.33% - 5rem);
	}

	&__list {
		width: 66.66%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__item {
		width: calc(50% - 0.9rem);
		margin-bottom: 1.9rem;

		h3 {
			margin-bottom: 0;
			border-left: 1px solid $color-eleven;
			padding-left: 1rem;
		}
	}

	@media (max-width: 770px) {
		&__heading,
		&__list {
			width: 100%;
		}
	}

	@media (max-width: 520px) {
		&__item {
			width: 100%;
		}
	}
}
