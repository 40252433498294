$footer-b-1: 1100px;

.footer {
	a {
		text-decoration: none;
		color: $color-three;
	}

	&__container {
		padding-top: 4.3rem;
		padding-bottom: 4.3rem;

		@media (min-width: $footer-b-1) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: flex-start;
		}
	}

	&__links {
		display: flex;
		flex-wrap: wrap;
		// align-items: center;
	}

	&__links-item {
		margin: 0 1.25rem 0 0;

		&--text {
			margin: 1rem 1.25rem 0 0;
			width: 100%;

			@media (min-width: $footer-b-1) {
				width: unset;
				margin: 0 1.25rem 0 0;
			}

			a {
				display: block;
				margin-top: 1ex;

				&:first-of-type {
					margin-top: 0.5ex;
				}

				&:last-of-type {
					margin-top: 2ex;
				}
			}
		}

		img {
			max-width: 2rem;
		}
	}

	&__text {
		margin: 1rem 0 0;

		a {
			color: #408077;
			// Darker tint of the main brand green for accessability.
			// This is the only instance where this color is used.
		}

		@media (min-width: $footer-b-1) {
			margin: 0;
			text-align: right;
			top: 5px;
		}
	}
}
