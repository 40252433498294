$column-gutter: 1.8rem;

.columns {
	color: $color-one;
	background-color: $color-three;

	&__container {}

	&__content {
		display: flex;
		flex-wrap: wrap;
		margin-right: - $column-gutter;
	}

	&__heading {
		width: calc(100% - #{$column-gutter});
		margin-bottom: 1.5rem;

		&--margin {
			margin-bottom: 4.75rem;
		}
	}

	&__copy {
		width: 100%;
		margin-bottom: 4.75rem;

		&::before {
			content: "";
			display: block;
			margin-bottom: 1rem;
			width: 3.5rem;
			height: 1px;
			background-color: #fff;
		}

		@media (min-width: 650px) {
			padding-right: 45%;
		}
	}

	&__item {
		width: calc(33.33% - #{$column-gutter});
		margin: 0 $column-gutter 4.3rem 0;
		text-decoration: none;
		color: inherit;

		p a {
			color: $color-eleven;
			text-decoration: none;
		}
	}

	&__icon {
		max-width: 3.4rem;
		max-height: 3.4rem;
		margin-bottom: 0.5rem;
	}

	&__thumbnail {
		margin-bottom: 0.8rem;
	}

	&__subheading {
		margin-bottom: 0.8rem;
	}

	&__dropdown-toggle {
		padding: 0.25rem;
		max-width: 1.5rem;
		cursor: pointer;
		transition: $transition-one;

		&--active {
			transform: rotate(180deg);
		}
	}

	&__dropdown-content {
		p a,
		li a {
			color: $color-eleven;
			text-decoration: none;
		}
	}

	@media (max-width: 920px) {
		&__item {
			width: calc(50% - #{$column-gutter});
		}
	}

	@media (max-width: 640px) {
		&__heading {
			margin-bottom: 2.75rem;
		}

		&__item {
			width: calc(100% - #{$column-gutter});
			margin: 0 $column-gutter 2.3rem 0;
		}
	}
}

