@font-face {
	font-family: 'TeXGyreAdventor';
	src: url('/fonts/TeXGyreAdventor/TeXGyreAdventor-Bold.eot');
	src: url('/fonts/TeXGyreAdventor/TeXGyreAdventor-Bold.eot?#iefix') format('embedded-opentype'),
		url('/fonts/TeXGyreAdventor/TeXGyreAdventor-Bold.woff2') format('woff2'),
		url('/fonts/TeXGyreAdventor/TeXGyreAdventor-Bold.woff') format('woff'),
		url('/fonts/TeXGyreAdventor/TeXGyreAdventor-Bold.ttf') format('truetype'),
		url('/fonts/TeXGyreAdventor/TeXGyreAdventor-Bold.svg') format('svg');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'TeXGyreAdventor';
	src: url('/fonts/TeXGyreAdventor/TeXGyreAdventor-BoldItalic.eot');
	src: url('/fonts/TeXGyreAdventor/TeXGyreAdventor-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('/fonts/TeXGyreAdventor/TeXGyreAdventor-BoldItalic.woff2') format('woff2'),
		url('/fonts/TeXGyreAdventor/TeXGyreAdventor-BoldItalic.woff') format('woff'),
		url('/fonts/TeXGyreAdventor/TeXGyreAdventor-BoldItalic.ttf') format('truetype'),
		url('/fonts/TeXGyreAdventor/TeXGyreAdventor-BoldItalic.svg') format('svg');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'TeXGyreAdventor';
	src: url('/fonts/TeXGyreAdventor/TeXGyreAdventor-Regular.eot');
	src: url('/fonts/TeXGyreAdventor/TeXGyreAdventor-Regular.eot?#iefix') format('embedded-opentype'),
		url('/fonts/TeXGyreAdventor/TeXGyreAdventor-Regular.woff2') format('woff2'),
		url('/fonts/TeXGyreAdventor/TeXGyreAdventor-Regular.woff') format('woff'),
		url('/fonts/TeXGyreAdventor/TeXGyreAdventor-Regular.ttf') format('truetype'),
		url('/fonts/TeXGyreAdventor/TeXGyreAdventor-Regular.svg') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'TeXGyreAdventor';
	src: url('/fonts/TeXGyreAdventor/TeXGyreAdventor-Italic.eot');
	src: url('/fonts/TeXGyreAdventor/TeXGyreAdventor-Italic.eot?#iefix') format('embedded-opentype'),
		url('/fonts/TeXGyreAdventor/TeXGyreAdventor-Italic.woff2') format('woff2'),
		url('/fonts/TeXGyreAdventor/TeXGyreAdventor-Italic.woff') format('woff'),
		url('/fonts/TeXGyreAdventor/TeXGyreAdventor-Italic.ttf') format('truetype'),
		url('/fonts/TeXGyreAdventor/TeXGyreAdventor-Italic.svg') format('svg');
	font-weight: 400;
	font-style: italic;
}
