$nav-break-one: 900px;

// ******************************
// Desktop nav
// ******************************
@media (min-width: $nav-break-one) {
	.header__nav-toggle {
		display: none;
	}

	.nav {
		position: absolute;
		top: 50%;
		right: 2rem;
		transform: translateY(-50%);
		z-index: 10;

		&__container {
			padding: unset;
			max-width: unset;
			margin: unset;
			width: unset;
		}

		&__list {
			display: flex;
			justify-content: flex-end;
		}	

		&__item {
			margin: 0 0 0 2rem;

			a {
				@include font-preset--6;
				color: $color-one;
				text-decoration: none;
				transition: color 0.2s;
				
				&:hover {
					color: $color-eleven;
				}
			}
		}

		&__contact {
			display: none;
		}
	}	
}

// ******************************
// Mobile nav
// ******************************
@media (max-width: $nav-break-one) {
	.nav {
		display: block;
		position: absolute;
		top: -2px;
		left: 0;
		height: 101vh;
		width: 100%;
		background-color: $color-three;
		z-index: 1;
		transform: translateY(-100%);
		transition: ease 0.3s;

		&--active {
			transform: translateY(0);
			.nav__container {
				opacity: 1;
				transform: translateY(0);
			}
		}

		&__container {
			opacity: 0;
			transform: translateY(-2rem);
			transition: $transition-three;
			padding-top: 7.5rem;
		}

		&__list {
			// max-height: 45vh;
			// overflow: scroll;
			margin-bottom: 6rem;
		}

		&__item {
			display: block;
			margin-bottom: 0.5rem;

			a {
				display: block;
				text-decoration: none;
				color: $color-one;
				margin: 0;

				@media (min-width: $font-break-1) {
					font-size: 3.5rem;
				}
			}
		}

		&__contact {
			display: flex;
			align-items: center;
		}

		&__contact-item {
			display: inline-block;

			&:first-of-type {
				margin-right: 2.5rem;

				img {
					max-width: 1rem;
				}
			}

			a {
				display: flex;
				align-items: center;
				text-decoration: none;
				color: $color-one;
			}

			img {
				max-width: 1.5rem;
				margin-right: 1.25rem;
			}
		}

		@media (max-width: 800px) {
			&__list {
				margin-bottom: 3rem;
			}
		}

		@media (max-width: 520px) {
			&__contact {
				display: block;
			}

			&__contact-item {
				width: 100%;
				margin-bottom: 0.7rem;
			}
		}
	}
}