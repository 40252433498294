.link-block {
	background-color: $color-six;

	&__container {
		padding-top: 3.1rem;
		padding-bottom: 2.5rem;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__item {
		width: calc(50% - 8.1rem);

		&:first-of-type {
			&::after {
				content: "";
				position: absolute;
				top: 0;
				right: -8.1rem;
				height: 100%;
				width: 1px;
				background-color: $color-three;
			}
		}
	}

	&__link	{
		color: $color-three;
		text-decoration: none;
	}

	&__heading {
		margin-bottom: 1.5rem;
	}

	&__copy {
		margin: 0;
		@include font-preset--8;
	}

	@media (max-width: 900px) {
		&__item {
			width: calc(50% - 2.1rem);

			&:first-of-type {
				&::after {
					right: -2.1rem;
				}
			}
		}
	}

	@media (max-width: 650px) {
		&__item {
			width: 100%;

			&:first-of-type {
				margin-bottom: 2.5rem;
			}
		}
	}
}
