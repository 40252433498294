.button {
	display: inline-block;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 0.9rem;
	letter-spacing: 1px;
	padding: 0.8rem 2.5rem 0.9rem;
	border: 1px solid $color-eleven;
	color: $color-eleven;
	transition: $transition-one;
	cursor: pointer;
	overflow: hidden;

	div {
		z-index: 2;
	}

	&::after {
		z-index: 1;
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: $color-eleven;
		transform: translateY(100%);
		transition: ease 0.2s;
	}

	&:hover {
		color: $color-one;

		&::after {
			transform: translateY(0);
		}
	}

	&--light {
		border: 1px solid $color-one;
		color: $color-one;

		&::after {
			background-color: $color-one;
		}

		&:hover {
			color: $color-eleven;
		}
	}
}

