.scroll-image {
	height: 25vw;
	background-repeat: no-repeat;
	background-size: cover;

	&--tall {
		height: 52vw;
	}

	&--hero {
		margin-top: -6.1rem;
		background-attachment: fixed;
		background-size: 100vw 25vw;
	}

	@media (max-width: 700px) {
		height: 220px;

		&--hero {
			background-attachment: initial;
			background-size: cover;
		}
	}
}


