.bannerBlock {
	display: block;
	text-align: center;
	text-decoration: none;
	color: $color-one;
	background-size: cover;
	background-position: 50%;

	&--download {
		.arrow-link::after {
			transform: rotate(90deg) translate(0px, -2px);

			&:hover {
				transform: rotate(90deg) translate(0px, -2px);
			}
		}
	}

	&__container {
		padding: 6.7vw 2.1rem 15vw;

		@media (min-width: $superWide) {
			padding: 12.7vw 2.1rem 19vw;
		}
	}

	&--dark {
		color: $color-three;
	}

	&--left {
		text-align: left;

		.bannerBlock__container {
			margin: 0;
		}

		.bannerBlock__link {
			margin: 0 1rem 1.3rem 0;
		}
	}

	&__copy {
		margin-bottom: 3.1rem;
	}

	&__link {
		border: 1px solid currentColor;
		padding: 0.4rem 1.7rem 0.7rem;
		margin: 0 0.5rem 1.3rem;
		display: inline-block;
		white-space: nowrap;
	}
}
