.dark-header {
	.header {
		background-color: $color-three;
	}
}

.header {
	position: sticky;
	top: 0;
	z-index: 2;
	transition: background-color 0.3s ease;

	&--scrolling {
		background-color: $color-three;
	}

	&__container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 1.8rem;
		padding-bottom: 1.8rem;
		z-index: 2;
	}

	&__logo {
		max-width: 10rem;
		display: block;
	}

	&__nav-toggle {
		width: 2.1rem;
		height: 1.5rem;
		background-image: url('/img/nav-open.png');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: 50%;
		cursor: pointer;
		transition: $transition-one;

		&--active {
			background-image: url('/img/nav-close.png');
			transform: rotate(180deg);
		}
	}
}
