.container {
	width: 100%;
	// max-width: $container-one;
	padding-left: $space-one;
	padding-right: $space-one;
	margin: 0 auto;

	&--large {
		max-width: $container-three;
		padding: 6.7vw $space-one 6.9vw;
	}

	&--medium {
		max-width: $container-two;
	}

	&--narrow {
		max-width: $container-four;
		padding: 6.7vw $space-one 6.9vw;

		@media (min-width: $superWide) {
			max-width: $container-three;
		}
	}

	@media (max-width: 1370px) {
		&--large,
		&--narrow {
			padding: 6.5rem $space-one;
		}
	}

	@media (max-width: 680px) {
		&--large,
		&--narrow {
			padding: 4rem $space-one;
		}
	}
}

