.text {
	&--left {
	  text-align: left;
	}

	  &--right {
		  text-align: right;
	  }

	  &--center {
		  text-align: center;
	  }
  }

  .scroll {
	  &--none {
		  overflow: hidden;
	  }

	  &--x {
		  overflow-y: hidden;
		  overflow-x: auto;
	  }

	&--y {
	  overflow-x: hidden;
	  overflow-y: auto;
	}
  }

.bold {
	font-weight: 800;
}

  .italic {
	  font-style: italic;
  }

  .underline {
	  text-decoration: underline;
  }

  .upper {
	  text-transform: uppercase;
  }

.hairline {
	height: 2px;
	width: 3.75rem;
	background-color: rgba(255, 255, 255, 0.3);
	margin-bottom: 1rem;

	&--dark {
		background-color: $color-three;
	}
}

.img-loader {
	display: none;
}

.img-hidden {
	height: 0;
}

.arrow-link {
	text-decoration: none;
	color: $color-three;

	&::after {
		content: "";
		display: inline-block;
		background-image: url('/img/icon-arrow-right.svg');
		background-repeat: no-repeat;
		background-size: contain;
		width: 0.9ex;
		height: 1.7ex;
		top: 2px;
		margin-left: 0.8ex;
		transition: transform 0.5s ease;
	}

	&--light {
		color: #fff;

		&::after {
			background-image: url('/img/icon-arrow-right-light.svg');
		}
	}

	&:hover {
		&::after {
			transform: translatex(0.5ex);
		}
	}
}

.js-dropdown-content {
	ul {
		list-style: disc;
		padding-left: 1.3rem;
	}

	li {
		margin-bottom: 0.7rem;
	}
}
