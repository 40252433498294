/* import css normalise/reset library */
@import 'libs/normalize.scss';

/* import vars */
@import 'vars/variables.scss';

/* import base styles */
@import 'base/animations.scss';
@import 'base/base.scss';
@import 'base/font-face.scss';
@import 'base/helpers.scss';
@import 'base/layout.scss';
@import 'base/typography.scss';

/* import components */
@import 'components/bannerBlock.scss';
@import 'components/buttons.scss';
@import 'components/cards.scss';
@import 'components/columnBlocks.scss';
@import 'components/columns.scss';
@import 'components/copy-panel.scss';
@import 'components/features.scss';
@import 'components/footer.scss';
@import 'components/header.scss';
@import 'components/hero-slider.scss';
@import 'components/img-grid.scss';
@import 'components/info-block.scss';
@import 'components/link-block.scss';
@import 'components/nav.scss';
@import 'components/pagination.scss';
@import 'components/scroll-image.scss';
@import 'components/scrollAnimation.scss';
@import 'components/testimonials.scss';
@import 'components/video-slider.scss';

/* import 'sins' file - when you need to put a quick hacky fix in */
@import 'sins';
