$videoHero-b-1: 1300px;

.video-slider {
	color: $color-one;

	&__down-link {
		position: relative;
		z-index: 1;
		bottom: 4.2rem;
		max-width: $container-three;
		padding: 0 $space-one;
		margin: 0 auto;
	}

	&__down-link-arrow {
		width: 2.5rem;
		height: 1.25rem;
		display: block;
		background-image: url('/img/icon-down-arrow-light.svg');
		background-size: contain;
		background-repeat: no-repeat;
	}

	&__down-link-target {
		visibility: hidden;
		scroll-margin: 6rem;
	}

	&__badge {
		position: absolute;
		bottom: 3.5rem;
		width: 8rem;
		right: 2.1rem;

		@media (min-width: 400px) {
			width: 12.5rem;
		}
	}

	&__slides {}

	&__container {
		padding-top: 10rem;
		padding-bottom: 16rem;

		@media (min-width: $videoHero-b-1) {
			padding-top: 15rem;
			padding-bottom: 28rem;
		}
	}

	&__video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: -1;
	}

	&__slide {
		z-index: 1;
	}

	&__heading {
		margin-bottom: 0;
		max-width: 700px;
		
		p,
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			@include font-preset--2;
		}

		strong {
			color: $color-eleven;
			display: inline-block;
		}

		&::after {
			content: "";
			display: block;
			margin-top: 2rem;
			width: 3.7rem;
			height: 1px;
			background-color: #fff;
		}
	}

	.slick-dots {
		position: relative;
		text-align: left;
		bottom: 3rem;
		max-width: $container-three;
		padding: 0 $space-one 0 0;
		margin: 0 auto;
		left: 6rem;


		li {
			z-index: 2;
			width: 14px;
			height: 14px;
			border-radius: 100%;
			background-color: $color-one;
			margin: 0 1.25rem 0 0;

			&.slick-active {
				background-color: $color-eleven;
			}

			button {
				display: none;
			}
		}
	}
}
