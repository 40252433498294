$colBlock-b-1: 700px;

.columnBlocks {
	padding: 7px;

	+.columnBlocks {
		margin-top: -14px;
	}

	&__container {
		display: flex;
		flex-wrap: wrap;
		padding: 0;

		&--thirds {
			.columnBlocks__column--image {
				width: 100%;

				@media (min-width: $colBlock-b-1) {
					width: 33.33%;
				}
			}
		}
	}

	&__column {
		width: 100%;
		background-color: $color-six;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50%;
		padding: $space-one;
		display: flex;
		flex-direction: column;
		justify-content: center;
		border: 7px solid #fff;
		border-radius: 20px;

		@media (min-width: $colBlock-b-1) {
			width: 33.33%;

			&--middle {
				order: 3;
			}

			&--right {
				order: 4;
			}
		}



		&--crop-tl {
			background-position: 0;
		}

		&--crop-tr {
			background-position: 100% 0;
		}

		&--crop-br {
			background-position: 100% 100%;
		}

		&--crop-bl {
			background-position: 0 100%;
		}

		&--image {
			width: 100%;
			padding: 66.66% 0 0;
			order: 2;

			@media (min-width: $colBlock-b-1) {
				width: 66.66%;
				padding: 33.33% 0 0;
			}
		}

		p:last-of-type {
			margin: 0;
		}
	}

	&__strapline {
		color: $color-eleven;
		font-size: 1.25rem;
		margin-bottom: 0.4rem;
	}

	&__heading {
		margin-bottom: 0.75rem;
	}
}
