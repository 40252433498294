$hero-b-1: 1300px;
$hero-b-2: 700px;

.hero-slider {
	color: $color-one;

	&--override {
		.hero-slider__content {
			visibility: hidden;
		}

		.hero-slider__down-link {
			visibility: hidden;

			&--static {
				visibility: visible;
				display: block;
				z-index: 1;
				left: 2.1rem;
			}
		}
	}

	&--top {
		margin-top: -6.1rem;
	}

	&__static-content {
		position: absolute;
		z-index: 1;
		top: -1.7rem;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		pointer-events: none;
		max-width: 100%;

		@media (min-width: $hero-b-2) {
			max-width: 58%;
		}
	}

	&__container {
		height: 100%;
		min-height: 100vh;
		padding-top: 13rem;
		padding-bottom: 13rem;

		@media (min-width: $hero-b-1) {
			padding-top: 18rem;
			padding-bottom: 25rem;
		}
	}

	&__slide {
		background-size: auto 100%;
		background-repeat: no-repeat;
		background-position: 110%;
		background-color: $color-three;
		transition: background-position 1s ease 0.3s;

		&--dark {
			color: $color-three;

			.hero-slider__copy::before {
				background-color: $color-three;
			}

			.hero-slider__down-link {
				background-image: url('/img/icon-down-arrow-dark.svg');
			}
		}

		&.slick-active {
			background-position: 100%;

			.hero-slider__content {
				opacity: 1;
			}
		}
	}

	&__down-link {
		position: absolute;
		bottom: 3rem;
		width: 2.5rem;
		height: 1.25rem;
		display: block;
		background-image: url('/img/icon-down-arrow-light.svg');
		background-size: contain;
		background-repeat: no-repeat;

		&--static {
			display: none;
		}

		@media (min-width: $hero-b-1) {
			// top: 18rem;
		}
	}

	&__down-link-target {
		visibility: hidden;
		scroll-margin: 6rem;
	}

	&__badge {
		position: absolute;
		bottom: 2.2rem;
		width: 12.5rem;
		left: 7.5rem;
		z-index: 1;
	}

	&__content {
		transition: opacity 1.4s ease 0.3s;
		max-width: 58%;
		opacity: 0;
	}

	&__heading {
		margin-bottom: 2.5rem;
	}

	&__copy {
		margin: 0;
		max-width: 41rem;

		&::before {
			content: "";
			display: block;
			margin-bottom: 2.3rem;
			width: 3.7rem;
			height: 1px;
			background-color: $color-one;
		}
	}

	.slick-track {
		display: flex;
	}

	.slick-slide {
		height: inherit;
	}

	.slick-arrow {
		display: none;
	}

	.slick-dots {
		position: absolute;
		text-align: left;
		left: 0;
		right: 0;
		margin: 0 auto;
		// max-width: $container-three;
		padding: 0 $space-one;
		bottom: 10rem;

		li {
			width: 14px;
			height: 14px;
			border-radius: 100%;
			background-color: $color-one;
			margin: 0 1.25rem 0 0;

			&.slick-active {
				background-color: $color-eleven;
			}

			button {
				display: none;
			}
		}

		@media (min-width: $hero-b-1) {
			bottom: 30vh;
		}
	}
}

.slick-dotted.slick-slider {
	margin-bottom: 0 !important;
}
